var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"conversation"}},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{ref:"filters",attrs:{"title":"Informativo"}},[(_vm.isLoading)?_c('b-spinner'):_vm._e(),(!_vm.isLoading)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status","options":_vm.informativeStatus,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.status),callback:function ($$v) {_vm.$set(_vm.informativeData, "status", $$v)},expression:"informativeData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2005261863)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"type","options":_vm.informativeTypes,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.type),callback:function ($$v) {_vm.$set(_vm.informativeData, "type", $$v)},expression:"informativeData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1856492344)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Data de envio","label-for":"send_at"}},[_c('validation-provider',{attrs:{"name":"send_at","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"send_at","raw":false,"options":_vm.maskDate,"placeholder":"DD/MM/YYYY","disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.send_in),callback:function ($$v) {_vm.$set(_vm.informativeData, "send_in", $$v)},expression:"informativeData.send_in"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,856530728)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.name),callback:function ($$v) {_vm.$set(_vm.informativeData, "name", $$v)},expression:"informativeData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2699065531)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Referência","label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"Referência","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.reference),callback:function ($$v) {_vm.$set(_vm.informativeData, "reference", $$v)},expression:"informativeData.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3655329365)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Slug","label-for":"slug"}},[_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.slug),callback:function ($$v) {_vm.$set(_vm.informativeData, "slug", $$v)},expression:"informativeData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,245359541)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Lista de usuários","label-for":"usuarios"}},[_c('validation-provider',{attrs:{"name":"Lista de usuários","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"usuarios","state":errors.length > 0 ? false:null,"rows":"20","disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.users),callback:function ($$v) {_vm.$set(_vm.informativeData, "users", $$v)},expression:"informativeData.users"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3324086035)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Texto superior","label-for":"header"}},[_c('validation-provider',{attrs:{"name":"Texto superior","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.header),callback:function ($$v) {_vm.$set(_vm.informativeData, "header", $$v)},expression:"informativeData.header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3634018072)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Texto inferior","label-for":"footer"}},[_c('validation-provider',{attrs:{"name":"Texto superior","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":_vm.isSubmiting},model:{value:(_vm.informativeData.footer),callback:function ($$v) {_vm.$set(_vm.informativeData, "footer", $$v)},expression:"informativeData.footer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2928203394)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-buttons",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Voltar ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isSubmiting},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isUpdating() ? 'Alterar' : 'Incluir')+" "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }