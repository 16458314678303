<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card
          ref="filters"
          title="Informativo"
        >

          <b-spinner v-if="isLoading" />

          <div v-if="!isLoading">
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="status"
                        rules="required"
                      >
                        <b-form-select
                          id="status"
                          v-model="informativeData.status"
                          :options="informativeStatus"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tipo"
                      label-for="type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="type"
                        rules="required|min:1"
                      >
                        <b-form-select
                          id="type"
                          v-model="informativeData.type"
                          :options="informativeTypes"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Data de envio"
                      label-for="send_at"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="send_at"
                        rules="required|min:5"
                      >
                        <cleave
                          id="send_at"
                          v-model="informativeData.send_in"
                          class="form-control"
                          :raw="false"
                          :options="maskDate"
                          placeholder="DD/MM/YYYY"
                          :disabled="isSubmiting"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Nome"
                      label-for="nome"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nome"
                        rules="required|min:5"
                      >
                        <b-form-input
                          id="nome"
                          v-model="informativeData.name"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Referência"
                      label-for="reference"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Referência"
                        rules="required"
                      >
                        <b-form-input
                          id="reference"
                          v-model="informativeData.reference"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Slug"
                      label-for="slug"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Slug"
                        rules="required"
                      >
                        <b-form-input
                          id="slug"
                          v-model="informativeData.slug"
                          :state="errors.length > 0 ? false:null"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3">
                    <b-form-group
                      label="Lista de usuários"
                      label-for="usuarios"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Lista de usuários"
                        rules="required"
                      >
                        <b-form-textarea
                          id="usuarios"
                          v-model="informativeData.users"
                          :state="errors.length > 0 ? false:null"
                          rows="20"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Texto superior"
                      label-for="header"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Texto superior"
                        rules=""
                      >
                        <b-form-textarea
                          v-model="informativeData.header"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Texto inferior"
                      label-for="footer"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Texto superior"
                        rules=""
                      >
                        <b-form-textarea
                          v-model="informativeData.footer"
                          :disabled="isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    class="form-buttons"
                  >
                    <b-button
                      variant="outline-secondary"
                      @click="$router.go(-1)"
                    >
                      Voltar
                    </b-button>

                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="isSubmiting"
                      @click.prevent="validationForm"
                    >
                      {{ isUpdating() ? 'Alterar' : 'Incluir' }}
                      <b-spinner
                        v-if="isSubmiting"
                        small
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormSelect,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BButton,
  BBreadcrumb,
  BSpinner,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { maskDate } from '@/utils/masks'
import InformativeService from '@/services/informativeService'
import { formatDateDbToView } from '@/utils/helpers'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    Cleave,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    // Ripple,
  },
  props: {

  },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Informativos',
          to: { name: 'cms-informative' },
        },
        {
          text: this.isUpdating() ? 'Alterar' : 'Adicionar',
          active: true,
        },
      ],
      informativeTypes: [
        { text: 'Empresa', value: 'company' },
        { text: 'Representante', value: 'representative' },
      ],
      informativeStatus: [
        { text: 'Aguardando envio', value: 'pending' },
        { text: 'Enviado', value: 'sent' },
      ],
      informativeService: null,
      informativeData: null,

      maskDate,

      required,
      email,
      formatDateDbToView,
    }
  },

  created() {
    this.informativeService = new InformativeService()

    this.informativeData = {
      status: '',
      type: '',
      name: '',
      reference: '',
      slug: '',
      header: '',
      footer: '',
      send_in: '',
    }

    if (this.isUpdating()) {
      this.getInformative()
    } else {
      this.isLoading = false
    }
  },

  methods: {
    isUpdating() {
      return this.$route.params.id !== undefined
    },

    getInformative() {
      this.isLoading = true
      this.informativeService.get(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.informativeData = response.data.data.informative
          this.informativeData.users = this.informativeData.users.map(map => map.user_id).join('\n')
          this.informativeData.send_in = formatDateDbToView(this.informativeData.send_in)
        }
        this.isLoading = false
      })
    },

    postInformative() {
      this.isSubmiting = true
      if (this.isUpdating()) {
        this.informativeService.update(this.$route.params.id, this.informativeData).then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Informativo alterado!',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$router.go(-1)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: 'Não foi possível alterar o informativo!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          this.isSubmiting = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.isSubmiting = false
        })
      } else {
        this.informativeService.post(this.informativeData).then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Informativo inserido!',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.$router.go(-1)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: 'Não foi possível inserir o informativo!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          this.isSubmiting = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.isSubmiting = false
        })
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postInformative()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              text: 'Existem erros de validação no formulário',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
